<template>
    <div class="c-tab-navigation">
        <div class="d-flex justify-content-start align-items-center">
            <router-link
                v-for="(tab, index) in navigationTabs"
                :key="index"
                :to="`/${pageName}/${routeParamsId ? routeParamsId + '/' : ''}${tab.path}`"
                :class="`c-tab-navigation__item qa-navigation-tab-${tab.path}`" 
                @click.native="setGoBack"
                
            >
                <span class="c-tab-navigation__item-title">{{ tab.title }}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TabNavigation', 
    props: {
        routeParamsId: {
            type: String,
            default: null
        },
        pageName: {
            type: String, 
            default: null
        },
        navigationTabs: {
            type: Array, 
            default: null
        }
    },
    data() {
        return {
            tabNavigationCounter: 1
        }
    }, 
    beforeDestroy() {
        localStorage.removeItem("setPorftolioBackRoute") 
    },
    methods: {
        setGoBack() {
            this.tabNavigationCounter += 1 
            localStorage.setItem("setPorftolioBackRoute", this.tabNavigationCounter)
        }
    }
}
</script>
 


